<template>
  <div class="page">
    <div class="content-block">
      <form-card title="欢迎注册">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm formContent"
          @validate="formValidate"
        >
          <el-row class="margin-b10">
            <el-col :span="16" :offset="4" :xs="{span:22,offset:0}">
              <el-form-item label="邮箱" prop="email">
                <!-- 禁止浏览器自动填充密码，勿删-->
                <input type="password" style="position:absolute;width:0;height:0;z-index: -999" />
                <el-input
                  aria-label="邮箱"
                  aria-autocomplete="none"
                  clearable
                  placeholder="请输入邮箱地址"
                  type="text"
                  v-model="ruleForm.email"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="margin-b10">
            <el-col :span="12" :offset="4" :xs="{span:14,offset:0}">
              <el-form-item label="图片验证码" prop="imgCaptcha" :error="imgCaptError">
                <el-input
                  aria-label="图片验证码"
                  aria-autocomplete="none"
                  clearable
                  autocomplete="off"
                  maxlength="4"
                  aria-valuemax="4"
                  show-word-limit
                  placeholder="请输入图片中的验证码"
                  v-model="ruleForm.imgCaptcha"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="3" :offset="1" :xs="{span:7}">
              <img class="verifiImg" :src="imgKeyData.img" alt="图片验证码" />
              <div role="button" class="refresh cursor" @click="refreshCode()">点击刷新</div>
            </el-col>
          </el-row>
          <el-row class="margin-b10">
            <el-col :span="12" :offset="4" :xs="{span:14,offset:0}">
              <el-form-item label="验证码" prop="emailCaptcha" :error="emilCaptError">
                <input type="password" style="display: none;" />
                <el-input
                  aria-label="验证码"
                  aria-autocomplete="none"
                  clearable
                  v-model="ruleForm.emailCaptcha"
                  placeholder="请输入邮箱收到的验证码"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="3" :offset="1" :xs="{span:7,}">
              <div
                v-if="count==60"
                class="sendCodeButton cursor"
                :class="{'btnDisabled':!formValid.email || !formValid.imgCaptcha}"
                @click="(formValid.email && formValid.imgCaptcha) && sendCode()"
              >发送验证码</div>
              <div v-else class="sendCodeButton cursor btnDisabled">请{{count}}s后再试</div>
            </el-col>
          </el-row>

          <el-row class="margin-b10">
            <el-col :span="16" :offset="4" :xs="{span:22,offset:0}">
              <el-form-item label="密码" prop="password">
                <el-input
                  aria-label="密码"
                  aria-autocomplete="none"
                  clearable
                  type="password"
                  v-model="ruleForm.password"
                  placeholder="请填写密码，需同时包含大小写字母和数字"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="margin-b10">
            <el-col :span="16" :offset="4" :xs="{span:22,offset:0}">
              <el-form-item label="确认密码" prop="passwordConfirm">
                <el-input
                  aria-label="确认密码"
                  aria-autocomplete="none"
                  clearable
                  type="password"
                  v-model="ruleForm.passwordConfirm"
                  placeholder="请再次填写密码，需同时包含大小写字母和数字"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="16" :offset="4" :xs="{span:22,offset:0}">
              <el-form-item class="text-center">
                <div
                  role="button"
                  class="registerButton"
                  :class="{btnDisabled:(Object.keys(formValid).length != Object.keys(rules).length) || !agreeStatus}"
                  @click="submitForm('ruleForm')"
                >注 册</div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-row style="text-align: left;">
          <el-col :span="12" :offset="4" :xs="{span:22,offset:0}">
            <el-checkbox
              v-model="agreeStatus"
              style="margin-left: 100px"
              :aria-checked="agreeStatus"
            >
              <span style="color: #000,font-w">同意</span>
              <a  class="serviceDec" href="/privacy" target="_blank">《ITGSA数据使用、隐私与个人信息保护协议》</a>
            </el-checkbox>
          </el-col>
          <el-col class="loginLink" :span="4" :xs="{span:21,offset:3}">
            <a class="serviceDec" href="/account/login" target="_blank">前往登录</a>
          </el-col>
        </el-row>
      </form-card>
    </div>
  </div>
</template>

<script>
import { passWord } from "@/utils/RegExp.js";
import utils from "@/utils/util.js";
import { singleAccountSever } from "@/common/api-config";
import FormCard from "@/components/FormCard.vue";

export default {
  name: "Register",
  components: { FormCard },
  data() {
    // 邮箱
    var checkEmail = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入邮箱"));
      } else {
        singleAccountSever
          .checkEmail({
            email: this.ruleForm.email,
          })
          .then((result) => {
            this.emailError = "";
            if (result.code == 0) {
              callback();
            } else {
              callback(new Error(result.message));
            }
          });
      }
    };

    //2次确定密码
    var confirmPassWord = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    //验证码检验
    var checkImgCaptcha = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入图形中的验证码"));
      } else if (value.length == 4) {
        singleAccountSever
          .checkImgCaptcha({
            imgCaptcha: value,
            ckey: this.ruleForm.ckey,
          })
          .then((result) => {
            if (result.code == 0) {
              callback();
            } else if (result.code == 1019) {
              // 图片验证码过期，需要重新获取图片验证码
              this.refreshCode();
              callback(new Error("图形验证码已失效"));
            } else {
              callback(new Error("图形验证码输入错误"));
            }
          });
      } else {
        callback(new Error("图片验证码错误"));
      }
    };
    return {
      count: 60, // 倒计时
      agreeStatus: false,
      emilCaptError: "", // 自定义后台返回的邮箱验证码错误提示
      emailError: "", // 自定义后台返回的邮箱是否已注册提示
      imgCaptError: "", // 自定义后台返回的图片验证码错误提示
      imgKeyData: {},
      formValid: {}, //用于检验form是否通过
      ruleForm: {
        email: "",
        ckey: "",
        imgCaptcha: "",
        emailCaptcha: "",
        password: "",
        passwordConfirm: "",
      },
      rules: {
        email: [
          { required: true, validator: checkEmail, trigger: "blur" },
          {
            type: "email",
            message: "请输入合法的邮箱地址",
            trigger: "blur",
          },
        ],
        emailCaptcha: [
          {
            required: true,
            message: "请输入邮箱收到的验证码",
            trigger: "blur",
          },
        ],
        imgCaptcha: [
          {
            required: true,
            validator: checkImgCaptcha,
            trigger: ["change"],
          },
        ],
        password: [
          { required: true, validator: passWord, trigger: ["blur", "change"] },
        ],
        passwordConfirm: [
          {
            required: true,
            validator: confirmPassWord,
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  mounted() {
    this.getImgCaptcha();
  },
  methods: {
    //检查每个input是否通过
    formValidate(key, valid) {
      if (valid) {
        this.$set(this.formValid, key, valid);
      } else {
        this.$delete(this.formValid, key);
      }
    },
    submitForm(formName) {
      this.emilCaptError = "";
      this.imgCaptError = "";
      if (this.formValid && this.agreeStatus) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let formData = utils.deepClone(this.ruleForm);
            formData.password = this.$md5(
              this.ruleForm.password + this.configBaseSALT
            );
            formData.passwordConfirm = this.$md5(
              this.ruleForm.passwordConfirm + this.configBaseSALT
            );
            singleAccountSever.register(formData).then((result) => {
              if (result.code == 0) {
                localStorage.setItem("Token", result.data.token);
                this.$store.dispatch(
                  "accountStore/updateAccountInfo", 
                  {},
                ).then((res) => {
                  this.$router.push({ path: "registerSuccess" });
                });
                this.$store.dispatch(
                  "accountStore/updateUserData", {},
                ).then((res) => {});
                this.resetForm("ruleForm");
              } else if (result.code == 1018) {
                this.emilCaptError = result.message;
              } else if (result.code == 1019) {
                this.imgCaptError = result.message;
              }  else {
                Message.error(result.message)
              }
            });
          } else {
            return false;
          }
        });
      }
    },
    refreshCode() {
      this.getImgCaptcha();
    },
    getImgCaptcha() {
      singleAccountSever.getImgCaptcha().then((result) => {
        if (result.code == 0) {
          this.imgKeyData = result.data[0].captcha;
          this.ruleForm.ckey = this.imgKeyData.key;
        } else if (result.code == 1019) {
          this.getImgCaptcha();
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    sendCode() {
      // 如果邮箱输入正确，则发送验证码，并开启倒计时
      this.$refs["ruleForm"].validateField("email", (valid) => {
        if (!valid) {
          singleAccountSever
            .sendEmailCaptcha({
              email: this.ruleForm.email,
              type:2,
            })
            .then((result) => {
              if (result.code == 0) {
                //倒计时
                this.timer = setInterval(() => {
                  this.count--;
                  if (this.count < 0) {
                    clearInterval(this.timer);
                    this.count = 60;
                  }
                }, 1000);
              } else {
                this.$message.error("发送失败");
              }
            });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.el-col-17 {
  padding-left: 0px !important;
}
/deep/ .el-form-item__content {
  line-height: 0px;
}

.page {
  background-color: #f8f6f7;
  padding: 60px 0px 70px 0px;
  height: 100%;
  .formContent {
    margin: auto;
    .sendCodeButton {
      width: 100%;
      height: 40px;
      border-radius: 4px;
      text-align: center;
      line-height: 40px;
      opacity: 1;
      text-align: center;
      background: rgba(40, 38, 44, 1);
      box-shadow: 0px 7px 8px 0px rgba(0, 0, 0, 0.07);
      color: #ffffff;
    }
    .btnDisabled {
      opacity: 0.25;
      cursor: not-allowed;
    }
    .buttonDisable {
      width: 100%;
      height: 40px;
      text-align: center;
      line-height: 40px;
      background-color: rgba(40, 38, 44, 0.48);
      color: #ffffff;
    }
  }
  .verifiImg {
    width: 100%;
    display: block;
    height: 36px;
    border-radius: 4px;
  }
  .refresh {
    text-align: right;
    font-size: 12px;
    font-weight: 400;
    color: rgba(153, 153, 153, 1);
    line-height: 30px;
    &:hover {
      text-decoration: underline;
    }
  }
  .verifiCode {
    cursor: pointer;
    margin: auto;
    display: block;
    margin-top: 7px;
    width: 19px;
    height: 19px;
    background: url("~@/assets/img/account/refresh.png") no-repeat;
    background-size: 100% 100%;
  }
  .registerButton {
    height: 50px;
    line-height: 50px;
    text-align: center;
    background: rgba(40, 38, 44, 1);
    box-shadow: 0px 7px 8px 0px rgba(0, 0, 0, 0.07);
    color: #ffffff;
    font-size: 18px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    border-radius: 5px;
    cursor: pointer;
  }
  .active {
    opacity: 1;
    cursor: pointer;
  }
  .extraContent {
    margin-left: 31%;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 400;
    color: rgba(102, 102, 102, 1);
    line-height: 60px;
  }
  .serviceDec {
    color: #43b6ff;
    &:hover {
      text-decoration: underline;
    }
  }
  .loginLink {
    text-align: right;
    &:hover {
      text-decoration: underline;
    }
  }
  @media screen and (max-width: 767px) {
    .loginLink {
      margin-top: 20px;
      text-align: center;
    }
  }
}
@media screen and (max-width: 767px) {
  .page {
    padding: 0px 0px 70px 0px;
  }
}
</style>